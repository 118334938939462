// Override default variables before the import
// $body-bg: #000;

// $form-label-color: #1DB8EA;

// @import "bootstrap/scss/bootstrap";

@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/solid";

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.1.1/css/all.min.css");

html {
    position: relative;
    min-height: 100%;
}

body {
    background: url(./images/Background.png) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    font-family: Roboto !important;
    margin: 0 0 500px !important;
}

@media screen and (max-width: 767px) {
    body {
        background: url(./images/Background.png) no-repeat center center fixed;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        font-family: Roboto !important;
        margin: 0 0 820px !important;
    }  
}

@media screen and (max-width: 450px) {
    body {
        background: url(./images/Background.png) no-repeat center center fixed;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        font-family: Roboto !important;
        margin: 0 0 420px !important;
    }  
}

footer {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    overflow:hidden;
}

.footer-line-item {
    color: #000046;
    text-decoration: none;
}

h3 {
    font: 600 16pt Montserrat !important;
    color: #000046;
}

input {
    font-size: 14pt !important;
}

#progressbar {
    margin-bottom: 30px;
    overflow: hidden;
    color: white;
    font: 12pt Montserrat;
    pointer-events: none;
}

.nav-step {
    pointer-events: all;
}

#progressbar .active {
    color: #000046;
}

#progressbar .current {
    font-weight: 600;
}

#progressbar li.current:before {
    background: #000046;
    border: none;
}

#progressbar li.current:after {
    content: "";
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg, #000046 50%, #ffffff 50%) !important;
    position: absolute;
    left: 0;
    top: 20px;
    z-index: -1;
    border: none;
}

#progressbar li.current.final:after {
    background: #000046 !important;
}

#progressbar li {
    list-style-type: none;
    font-size: 15px;
    width: 20%;
    float: left;
    position: relative;
    font-weight: 500;
}

#progressbar #product-selection:before {
    font-family: "Font Awesome 5 Free";
    content: "\f0ea";
    font-weight: 900;
    pointer-events: all;
}

#progressbar #select-treatments:before {
    font-family: "Font Awesome 5 Free";
    content: "\f481";
    font-weight: 900;
    pointer-events: all;
}

#progressbar #patient-information:before {
    font-family: "Font Awesome 5 Free";
    content: "\f46d";
    font-weight: 900;
    pointer-events: all;
}

#progressbar #review:before {
    font-family: "Font Awesome 5 Free";
    content: "\f46c";
    font-weight: 900;
    pointer-events: all;
}

#progressbar #submit:before {
    font-family: "Font Awesome 5 Free";
    content: "\f164";
    font-weight: 900;
    pointer-events: all;
}
#progressbar li:before {
    width: 40px;
    height: 40px;
    line-height: 35px;
    display: block;
    font-size: 20px;
    color: #ffffff;
    background: #96def6;
    border-radius: 50%;
    margin: 0 auto 10px auto;
    padding: 0;
    border: 2px solid white;
}

#progressbar li:after {
    content: "";
    width: 100%;
    height: 2px;
    background: white;
    position: absolute;
    left: 0;
    top: 20px;
    z-index: -1;
}

#progressbar li.active:before {
    background: #000046;
    border: none;
    padding: 2px;
}

#progressbar li.active:after {
    background: #000046;
    border: none;
}

// media query also in BuilderProgressBar.js
@media screen and (min-width: 450px) and (max-width: 767px) {
    #progressbar li {
        list-style-type: none;
        font-size: 15px;
        width: 33%;
        float: left;
        position: relative;
        font-weight: 400;
    }
}

@media screen and (max-width: 450px) {
    #progressbar li {
        list-style-type: none;
        font-size: 15px;
        width: 50%;
        float: left;
        position: relative;
        font-weight: 400;
    }
}

.form-control:read-only {
    border: 0 !important;
    background-color: inherit !important;
}

select[readonly]:read-only {
    pointer-events: none;
    border: 0;
}

.border-down {
    border-bottom: 1px solid lightgray;
}

.row-striped:nth-of-type(even) {
    background-color: #e6e6e6;
}

.row-striped:nth-of-type(odd) {
    background-color: #f2f2f2;
}

.navbar-light {
    background-color: inherit !important;
}

.left-heading {
    font: 500 14pt Montserrat;
}

.left-heading:hover {
    text-decoration: underline;
    text-decoration-thickness: 2px !important;
    color: #000046;
}

$input-placeholder-color: #a0a0a0;

$form-feedback-invalid-color: #bb0046;

$form-check-input-border: 2px solid #1db8ea;
// $form-check-input-checked-color: #1db8ea;
$form-check-input-checked-bg-color: #1db8ea;
$form-check-input-checked-border-color: #1db8ea;
$form-check-input-focus-box-shadow: none;
$btn-focus-box-shadow: none;
$form-select-focus-box-shadow: none;

$input-focus-border-color: #1db8ea;

// Import Bootstrap and its default variables
@import "bootstrap/scss/bootstrap.scss";

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active {
    box-shadow: none !important;
}

.form-control:focus {
    // border-color: #6265e4 !important;
    box-shadow: none !important;
    background-color: #d2f1fb;
}

.form-control.is-invalid {
    background-color: #f0c7d6;
}

.react-datepicker-wrapper {
    display: flex !important;
}

.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
    background-color: #1db8ea !important;
}
.react-datepicker__day--keyboard-selected:hover, .react-datepicker__month-text--keyboard-selected:hover, .react-datepicker__quarter-text--keyboard-selected:hover, .react-datepicker__year-text--keyboard-selected:hover {
    background-color: #1db8ea !important;
}
.react-datepicker__day--selected {
    background-color: #1db8ea !important;
}
.react-datepicker__day--selected:hover {
    background-color: #1db8ea !important;
}

.react-datepicker__day--highlighted-custom {
    border-radius: 0.3rem;
    background-color: #ce0044;
    color: #fff !important;
    cursor: pointer;
}

.react-datepicker__day--highlighted-custom:hover {
    background-color: #ce0044 !important;
    color: #fff !important;
}

.react-datepicker__year-select {
    border-bottom: 1px solid #aeaeae !important;
    border-radius: 0 !important;
    background-color: #f0f0f0 !important;
    border-top: 0px !important;
    border-left: 0px !important;
    border-right: 0px !important;
}

.react-datepicker__month-select {
    border-bottom: 1px solid #aeaeae !important;
    border-radius: 0 !important;
    background-color: #f0f0f0 !important;
    border-top: 0px !important;
    border-left: 0px !important;
    border-right: 0px !important;
}

.doc-link {
    cursor: pointer;
    color: #1db8ea;
}

.doc-link:hover {
    color: #049e8c;
}

// --------- Frontpage ---------

.mcp-columns {
    padding-top: 20px;
    padding-bottom: 15px;
    border-radius: 30px;
    // background-color: #000000a6;
    background-color: rgba(0, 0, 0, 0.25);
    box-shadow: inset 0px 0px 20px 10px rgb(0 0 0 / 30%);
}

.scroll-to-section {
    margin-top: 2.0rem;
    cursor: pointer;
    transition: 0.15s;
}

.scroll-to-section:hover {
    transform: scale(1.08);
    z-index: 99;
}

.bottom-scroll-to-section {
    z-index: 2147483000;
    cursor: pointer;
    position: fixed;
    bottom: 85px;
    left: 25px;
}

.mcp-column {
    background: white;
    color: #212529;
    border-bottom: none;
    // cursor: pointer;
    // transition: 0.15s;
    border-top-right-radius: 0.9em;
    border-bottom-right-radius: 0.9em;
}

// .mcp-column:hover {
//     box-shadow: 1px 0px 20px 8px rgb(0 0 0 / 35%), 0 0 2px rgb(0 0 0 / 15%);
//     transform: scale(1.05);
//     z-index: 99;
// }

.future-custom-header {
    background-color: #000046;
    border-top-left-radius: 1em !important;
    border-top-right-radius: 1em !important;
    color: white;
    padding: 32px;
    cursor: pointer;
    transition: 0.15s;
}

.future-custom-header:hover {
    box-shadow: 1px 0px 20px 8px rgb(0 0 0 / 35%), 0 0 2px rgb(0 0 0 / 15%);
    transform: scale(1.05);
    z-index: 99;
}

.download-mcp-file-span {
    position: relative;
    cursor: pointer;
}

.download-mcp-file-span:hover .hidden_img {
    display: block;
}

.download-mcp-file-span .hidden_img {
    position: absolute;
    top: -20px;
    left: 100px;
    font-size: 80px;
    opacity: 0.75;
    display: none;
}

// --------- Standard MCP Card -----------

.product-heading {
    font: 700 25pt Montserrat;
    // color: white;
    font-variant: small-caps;
};

.pro-product-heading {
    font: 700 25pt Montserrat;
    // color: #dadae5;
    // color: white;
    font-variant: small-caps; 
}

.product-capital {
    font: 700 23pt Montserrat;
};

.product-ul-style {
    font: 500 12pt Roboto; 
    // color: white
}

.pro-product-ul-style {
    font: 500 12pt Roboto;
    // color: #c2c2d3;
    // color: white;
}

.product-bullet {
    margin-bottom: 10px;
};

// --------- Feedback / Alert ------------

.feedback-div {
    position: fixed;
    width: 20%;
    bottom: 20px;
    right: 40px;
    margin-bottom: 60px;
    z-index: 2;
}

.feedback-close {
    cursor: pointer;
    position: relative;
    top: -23px;
    left: -5px;
    color: white;
}

.feedback-icon-error {
    position: relative;
    top: 18.5px;
    left: 10.1px;
    color: white;
}

.feedback-icon-warning {
    position: relative;
    top: 18.5px;
    left: 18.1px;
    color: white;
}

.feedback-bottom-border {
    border-bottom: 2px solid #1db8ea;
}

.feedback-bottom-border-error {
    border-bottom: 2px solid #ce0044;
    box-shadow: 0px -2px 8px 1px #ce0044;
}

.feedback-bottom-border-warning {
    border-bottom: 2px solid #ffcc00;
}

.feedback-background {
    background-color: #1db8ea;
}

.feedback-background-error {
    background-color: #ce0044;
}

.feedback-background-warning {
    background-color: #ffcc00;
}

.feedback-heading {
    margin-bottom: 0px;
    color: #00baee;
    font-weight: 500;
    font-size: 1.2rem;
}

.feedback-heading-error {
    margin-bottom: 0px;
    color: #ce0044;
    font-weight: 500;
    font-size: 1.2rem;
}

.feedback-heading-warning {
    margin-bottom: 0px;
    color: #efaa00;
    font-weight: 500;
    font-size: 1.2rem;
}

.feedback-msg {
    color: #8ecff0
}

.feedback-msg-error {
    color: #e67da0
}

.feedback-msg-warning {
    color: #ffcc00
}
//---------- Pagination ------------------

ul.pagination li a {
    border: none;
}

//---------- Tooltips --------------------
.__react_component_tooltip {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
    border-radius: 10px !important;
    -webkit-box-shadow: 0px 1px 8px 2px #b7b7cb;
    // &:after {
    //     border-top-color: #e8ff8b !important;
    // }
    -moz-box-shadow: 0px 1px 8px 2px #b7b7cb;
    // &:after {
    //     border-top-color: #e8ff8b !important;
    // }
    box-shadow: 0px 1px 8px 2px #b7b7cb;
    // &:after {
    //     border-top-color: #e8ff8b !important;
    // }
}

.__react_component_tooltip.show {
    opacity: 1 !important;
}

.close-tooltip {
    position: absolute;
    right: 5px;
    top: 1px;
}

//---------- Cards -----------------------

.info-card {
    border: none;
    border-radius: 1em;
}

.content-card {
    border: none;
    border-radius: 10px;
    margin: 0px 64px 0px 64px;
    box-shadow: 1px 0 8px rgba(0, 0, 0, 0.3), 0 0 2px rgba(0, 0, 0, 0.15);
    padding: 40px;
}

@media screen and (max-width: 576px) {
    .content-card {
        border: none;
        border-radius: 10px;
        margin: 0px;
    }
}

.card-close {
    position: absolute;
    right: 20px;
    top: 20px;
    color: #000046;
}

.card-close:hover {
    color: #1db8ea;
}

.card-right-hand-side {
    background: white;
    border-top-right-radius: 1em;
    border-bottom-right-radius: 1em;
}

.card-right-left-side {
    background: white;
    border-top-left-radius: 1em;
    border-bottom-left-radius: 1em;
}

//---------- Inputs ----------------------

#report-title {
    font-size: 12pt !important;
}

.access-input-label-style {
    color: #1db8ea;
}

.access-input-style {
    border-bottom: 2px solid #1db8ea;
    border-radius: 0;
    background-color: #eef0f4;
    padding-left: 12px;
    height: 41px;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
}

// Form control won't work on login inputs
.access-input-style.login:focus {
    // border-color: #6265e4 !important;
    box-shadow: none !important;
    background-color: #d2f1fb;
    outline: none;
}

.access-input-checkbox {
    border-color: #1db8ea;
}

.access-freq-input-style {
    border-top: 0;
    border-right: 0;
    border-left: 0;
    border-bottom: 2px solid #1db8ea;
    border-radius: 0;
    background-color: #ffffff;
}

.access-textare-style {
    border: none;
    border-bottom: 2px solid #1db8ea;
    border-radius: 0;
    background-color: #eef0f4;
}

.form-control[readonly].access-input-style {
    border-bottom: 2px solid #f2f4f7 !important;
}

.form-select[readonly].access-input-style {
    border-bottom: 2px solid #f2f4f7 !important;
}

.controller-invalid-feedback {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 0.875em;
    color: #bb0046;
}

.show-hide-password {
    border: none;
    border-bottom: 2px #1db8ea solid;
    background-color: #eef0f4;
}

.show-hide-password-invalid {
    border: none;
    border-bottom: 2px #bb0046 solid !important;
    background-color: #f0c7d6 !important;
}

.clear-input {
    background-color: white;
    border: none;
}

.email-input-style {
    border-bottom: 2px solid #1db8ea;
    border-radius: 0;
    background-color: #fff;
    padding-left: 12px;
    height: 41px;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
}

.email-input-style:focus {
    // border-color: #6265e4 !important;
    box-shadow: none !important;
    background-color: #fff;
    outline: none;
}

//---------- Buttons ---------------------

.btn-signup {
    color: #fff;
    background-color: #000046;
    border-radius: 20px;
    border: 0px;
    // border-color: #000046;
    font-weight: 700;
}

.btn-signup:hover {
    // background-color: #fff !important;
    border: 1px;
    border-color: #4cc6ee !important;
    // color: #1db8ea !important;
    background-image: linear-gradient(0.25turn, #1db8ea, #0a3d7d);
    color: #fff;
}

.access-primary-button {
    background-color: #000046;
    color: white;
    border-radius: 2em;
    // border-color: #000046;
    border-width: 0px;
    font-size: 12pt !important;
    padding: 0.575rem 1.25rem;
}

.access-primary-button:hover {
    // background-color: #1db8ea;
    // border: 1px solid #1db8ea;
    // color: #fff;
    background-image: linear-gradient(0.25turn, #1db8ea, #0a3d7d);
    color: #fff;
}

.access-primary-button:disabled {
    border-color: #b7b7cb;
    background-color: #b7b7cb;
    color: #8080a3;
}

.access-primary-btn-lg {
    background-color: #000046;
    color: white;
    // border-color: #000046;
    border-width: 0px;
    border-color: #fff;
    font-size: 12pt !important;
    padding: 0.6rem 3rem;
    border-radius: 40px;
}

.access-primary-btn-lg:hover {
    // background-color: #1db8ea;
    // border: 1px solid #1db8ea;
    // color: #fff;
    background-image: linear-gradient(0.25turn, #1db8ea, #0a3d7d);
    color: #fff;
}

.access-primary-btn-lg:disabled {
    border-color: #b7b7cb;
    background-color: #b7b7cb;
    color: #8080a3;
}

.access-primary-btn-lg-gradient {
    background-color: #000046;
    color: white;
    font-size: 12pt !important;
    border-color: #fff;
    border-width: 0px;
    padding: 0.6rem 1.6rem;
    border-radius: 2em;
}

.access-primary-btn-lg-gradient:hover {
    background-image: linear-gradient(0.25turn, #1db8ea, #0a3d7d);
    // border: 1px solid #1db8ea;
    color: #fff;
}

.access-primary-btn-lg-gradient:disabled {
    border-color: #b7b7cb;
    background-color: #b7b7cb;
    color: #8080a3;
}

.access-primary-blue {
    background-color: #1db8ea;
    color: white;
    border-width: 0px;
    // border-color: #1db8ea;
    font-size: 11pt !important;
    padding: 0.6rem 3rem;
    border-radius: 2em;
}

.access-primary-blue:hover {
    // background-color: #049e8c;
    // border: 1px solid #049e8c;
    color: #fff;
    background-image: linear-gradient(0.25turn, #1db8ea, #049e8c);
    border-color: #1db8ea;
}

.access-primary-blue:disabled {
    border-color: #b7b7cb;
    background-color: #b7b7cb;
    color: #8080a3;
}

.access-secondary-button {
    background-color: #1db8ea;
    color: white;
    border-radius: 2em;
    border-width: 0px;
    // border-color: #1db8ea;
    font-size: 11pt !important;
    font-weight: 500 !important;
    padding: 0.45em 1.35em;
    white-space: nowrap;
}

.access-secondary-button:hover {
    background-image: linear-gradient(0.25turn, #1db8ea, #049e8c);
    color: #fff;
}

.access-secondary-button:disabled {
    border-color: #b7b7cb;
    background-color: #b7b7cb;
    color: #8080a3;
}

.access-secondary-button-flipped {
    background-color: #000046;
    color: white;
    border-radius: 20px;
    border-color: #000046;
    font-size: 11pt !important;
    font-weight: 500 !important;
    height: 32px;
    padding-left: 20px;
    padding-right: 20px;
    white-space: nowrap;
}

.access-secondary-button-flipped:hover {
    background-color: #1db8ea;
    border: 1px solid #1db8ea;
    color: #fff;
}

.access-secondary-button-flipped:disabled {
    border-color: #b7b7cb;
    background-color: #b7b7cb;
    color: #8080a3;
}

.access-large-button {
    background-color: #000046;
    color: white;
    border-radius: 2em;
    border-color: #fff;
    font-size: 1.15rem !important;
    padding: 1rem 1rem;
}

.access-large-button:hover {
    background-image: linear-gradient(0.25turn, #1db8ea, #0a3d7d);
    // border: 1px solid #1db8ea;
    color: #fff;
}

.access-large-button:disabled {
    border-color: #b7b7cb;
    background-color: #b7b7cb;
    color: #8080a3;
}

button:disabled {
    cursor: not-allowed;
    pointer-events: all !important;
}

.btn {
    font: 600 11pt Montserrat;
}

// addon classes for Builder

.access-secondary-button-check {
    background-color: #049e8c !important;
    border-color: #049e8c !important;
    color: white;
    border-radius: 20px;
    font-size: 11pt !important;
    font-weight: 500 !important;
    height: 32px;
    padding-left: 20px;
    padding-right: 20px;
}

.access-secondary-button-another {
    background-color: #000046 !important;
    border-color: #000046 !important;
    color: white;
    border-radius: 20px;
    font-size: 11pt !important;
    font-weight: 500 !important;
    height: 32px;
    padding-left: 20px;
    padding-right: 20px;
}

.access-secondary-button-another:hover {
    background-color: #049e8c !important;
    border: 1px solid #049e8c !important;
    color: #fff;
}

.access-secondary-button-another:disabled {
    border-color: #b7b7cb !important;
    background-color: #b7b7cb !important;
    color: #8080a3;
}

.access-danger-button {
    background-color: #bb0046;
    color: white;
    border-radius: 20px;
    border-color: #bb0046;
    font-size: 11pt !important;
    font-weight: 500 !important;
    height: 32px;
    padding-left: 20px;
    padding-right: 20px;
    white-space: nowrap;
}

.access-danger-button:hover {
    background-color: #049e8c;
    border: 1px solid #049e8c;
    color: #fff;
}

.access-danger-button:disabled {
    border-color: #b7b7cb;
    background-color: #b7b7cb;
    color: #8080a3;
}

.beta-button {
    position: relative;
}

.beta-badge {
    width: auto;
    position: absolute;
    bottom: -2%;
    left: -9%;
    height: 150%;
}

.beta-badge-laptop {
    width: auto;
    position: absolute;
    bottom: 15%;
    left: -12%;
    height: 115%;
}

//---------- Images ----------------------

.transparent {
    background: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5));
}

.column-right-hand-side {
    background: white;
    border-top-right-radius: 1em;
    border-bottom-right-radius: 1em;
}

@media screen and (max-width: 1200px) {
    .column-right-hand-side {
        background: white;
        border-top-right-radius: 0;
        border-bottom-left-radius: 1em;
        border-bottom-right-radius: 1em;
    }
}

//---------- FAQ Page --------------------

.faq-questions {
    color: #1db8ea;
    font-weight: 500;
    font-size: 1.2em;
}

.faq-contact-link {
    color: #1db8ea;
    text-decoration: none;
}

.faq-contact-link:hover {
    color: #049e8c !important;
}

.faq-headings {
    font: 600 1.3em Montserrat !important;
    color: #000046;
}

.fade-faq-enter {
    opacity: 0;
}
.fade-faq-exit {
    opacity: 1;
}
.fade-faq-enter-active {
    opacity: 1;
}
.fade-faq-exit-active {
    opacity: 0;
}
.fade-faq-enter-active,
.fade-faq-exit-active {
    transition: opacity 350ms;
}

//---------- Contact Us -----------------

.contact-us-heading {
    font: 600 16pt Montserrat;
    color: #000046;
}

.contact-us-form input {
    border-bottom: 2px solid #1db8ea;
    border-radius: 0;
    background-color: #eef0f4;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
}

.contact-us-form textarea {
    border-bottom: 2px solid #1db8ea;
    border-radius: 0;
    background-color: #eef0f4;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
}

.contact-us-form label {
    color: #1db8ea;
}

.file-upload-label:hover {
    color: #049e8c;
}

.contact-thank-you {
    font: 600 32pt Montserrat !important;
    color: #000046;
}

@media screen and (max-width: 767px) {
    .our-info-borders {
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }

}

//---------- Fontawesome -----------------

// for future if needed, how to add outline to icons
// .icon-class g g path{
//     stroke: black;
//     stroke-width: 10;
// }

.fa-google path {
    fill: url(#grad1);
}
.fa-google + .fa-google path {
    fill: url(#grad2);
}
.icon {
    display: inline-block;
    position: relative;
}
.fa-google + .fa-google {
    position: absolute;
    left: 0;
    top: 0;
    clip-path: polygon(0% 0%, 120% 0%, 0% 75%);
}

.fa-twitter {
    color: #1db8ea;
}
.fa-twitter:hover {
    color: #049e8c;
}

.fa-linkedin-in {
    color: #1db8ea;
}
.fa-linkedin-in:hover {
    color: #049e8c;
}

.fa-facebook-f {
    color: #1db8ea;
}
.fa-facebook-f:hover {
    color: #049e8c;
}

// Vertically alligned icon next to text
.icon-container {
    display: inline-block;
    padding: 10px;
}

.fa-center-icon {
    vertical-align: middle;
    margin-right: 12px;
    font-size: 2em;
}

.circle-icon {
    background: #1db8ea;
    padding: 7px;
    border-radius: 50%;
    color: white;
}

.circle-icon-marker {
    background: #1db8ea;
    padding: 7px;
    border-radius: 50%;
    color: white;
}

//--------- Package Search ------------

.package-search-form-input {
    border: 2px solid #1db8ea;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    // background-color: #eef0f4;
    height: 32;
    padding-left: 16px;
    box-shadow: 0 0 5px #1db8ea;
}

.package-search-form-input::placeholder {
    color: #a0a0a0;
}

.package-search-form-input:focus {
    // border: none;
    box-shadow: none;
    background-color: #d2f1fb;
    box-shadow: 0 0 5px #1db8ea !important;
    border-color: #1db8ea !important;
}

.package-search-form-input.is-invalid {
    border-color: #bb0046;
    background-color: #f0c7d6;
    box-shadow: none;
}

.package-search-button {
    background-color: #1db8ea;
    color: white;
    border-color: #1db8ea;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    padding-right: 14px;
    font-size: 18px;
    box-shadow: 0 0 5px #1db8ea;
}

.package-search-button:hover {
    background-image: linear-gradient(0.25turn, #1db8ea, #049e8c);
    box-shadow: 0 0 5px #1db8ea;
    border-color: #1db8ea;
}

.package-search-add {
    background-color: #000046;
    color: white;
    border-radius: 20px;
    border-color: #000046;
    padding: 4px 20px;
}

.package-search-add:hover {
    color: lightgray;
}

.package-card-container {
    border-radius: 0;
}

.package-card-header {
    border-bottom: none;
    border-radius: 0px !important;
}

.package-card-collapse-container {
    border-top: none !important;
    border-radius: 0 !important;
}

.package-card-collapse {
    padding: 0;
}

.row-striped-bills:nth-of-type(even) {
    background-color: #ecf9fd;
}

.row-striped-bills:nth-of-type(odd) {
    background-color: #d2f1fb;
}

.dnd-handle {
    font-size: 16px;
    position: absolute;
    left: 8px;
    top: 5px;
}

.custom-info-tooltip {
    z-index: 1065;
}

.standard-info-tooltip {
    z-index: 1065;
    max-width: 450px;
}

.pro-info-tooltip {
    z-index: 1065;
    max-width: 450px;
}

.expert-info-tooltip {
    z-index: 1065;
    text-align: left;
    max-width: 450px;
}

.occurrence-card {
    max-height: 0px;
    overflow-y: hidden;
    transition: max-height 0.5s ease;
}

.occurrence-card.expand {
    max-height: 1000px;
    transition: max-height 1.75s ease;
}

.bills-card {
    max-height: 0px;
    overflow-y: hidden;
    transition: max-height 0.5s ease;
}

.bills-card.expand {
    max-height: 10000px;
    transition: max-height 1.75s ease;
}

.chevron {
    transform: rotate(0deg);
    transition: transform 0.5s ease;
}

.chevron.rotate {
    transform: rotate(180deg);
    transition: transform 0.5s ease;
}

//---------- Review Page -----------------

// media queries also in BuilderReview.js

.review-form input {
    padding-left: 0px;
}

//---------- Modal -----------------

.modal-backdrop {
    background-color: #05205c;
    opacity: 0.8 !important;
}

.modal-eula-sign {
    border-radius: 20px;
    padding: 35px;
}
@media (max-width: 450px) { 
    .modal-eula-sign {
        border-radius: 20px;
        padding: 20px;
    }
}

.create-password-modal {
    padding-bottom: 25px !important;
}

// Header

.nav-link,
.nav-item {
    color: white !important;
    font: 500 13pt Montserrat;
    margin-right: 20px;
}

.nav-link-style {
    display: inline-block;
    text-decoration: none;
}
.nav-link-style:hover {
    color: #000046;
}

.nav-link-span.active {
    color: #000046;
    // border-bottom: 1px solid #000046;
    padding-bottom: 3px;
    font: 600 13pt Montserrat;
    font-weight: 600;
}

.nav-link-span.inactive {
    font-weight: 500;
}

.offcanvas-header {
    display: none;
}

.nav-link-style.dropdown-toggle::after {
    transform: rotate( 360deg ) !important;
    transition: transform 0.2s ease !important;
}

.nav-link-style.dropdown-toggle.show::after {
    transform: rotate( 180deg ) !important;
    transition: transform 0.2s ease !important;
}

.dropdown-item.active, .dropdown-item:active {
    color: #fff !important;
    text-decoration: none;
    background-color: #000046;
}

ul.dropdown-menu li a:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}

ul.dropdown-menu li span:last-child {
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
}

// @media (max-width: 1080px) {
//     // xl bootstrap breakpoint
//     .offcanvas-header {
//         display: block;
//     }
//     .navbar-collapse {
//         position: fixed;
//         top: 0;
//         bottom: 70%;
//         left: 100%;
//         width: 35%;
//         padding-right: 1rem;
//         padding-left: 1rem;
//         overflow-y: auto;
//         visibility: hidden;
//         background: rgb(2, 36, 94); /* Fallback for older browsers without RGBA-support */
//         background: rgb(2, 36, 94, 0.8);
//         transition: visibility 0.2s ease-in-out, transform 0.2s ease-in-out;
//     }
//     .navbar-collapse.show {
//         visibility: visible;
//         transform: translateX(-100%);
//     }
//     .nav-link-span.active {
//         color: #1db8ea;
//         // border-bottom: 1px solid #000046;
//         padding-bottom: 3px;
//         font: 600 13pt Montserrat;
//         font-weight: 600;
//     }

//     .nav-link-span.middle:before {
//         background-color: #1db8ea !important;
//     }

//     .nav-link-span.middle.active:before {
//         background-color: #1db8ea !important;
//     }
// }

// @media (max-width: 991px) {
//     // lg bootstrap breakpoint
//     .offcanvas-header {
//         display: block;
//     }
//     .navbar-collapse {
//         position: fixed;
//         top: 0;
//         bottom: 45%;
//         left: 100%;
//         width: 70%;
//         padding-right: 1rem;
//         padding-left: 1rem;
//         overflow-y: auto;
//         visibility: hidden;
//         background: rgb(2, 36, 94); /* Fallback for older browsers without RGBA-support */
//         background: rgb(2, 36, 94, 0.8);
//         transition: visibility 0.2s ease-in-out, transform 0.2s ease-in-out;
//     }
//     .navbar-collapse.show {
//         visibility: visible;
//         transform: translateX(-100%);
//     }
//     .nav-link-span.active {
//         color: #1db8ea;
//         // border-bottom: 1px solid #000046;
//         padding-bottom: 3px;
//         font: 600 13pt Montserrat;
//         font-weight: 600;
//     }

//     .nav-link-span.middle:before {
//         background-color: #1db8ea !important;
//     }

//     .nav-link-span.middle.active:before {
//         background-color: #1db8ea !important;
//     }
// }

.nav-link-span.middle {
    position: relative;
}

.nav-link-span.middle:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: -5px;
    left: 0;
    background-color: #000046;
    visibility: hidden;
    transform: scaleX(0);
    transition: all 0.3s ease-in-out;
}

.nav-link-span.middle:hover:before {
    visibility: visible;
    transform: scaleX(1);
}

.nav-link-span.middle.active:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: -2px;
    left: 0;
    background-color: #000046;
    visibility: visible;
    transform: scaleX(1);
    transition: all 0.3s ease-in-out;
}

.nav-link-span.middle.active:hover:before {
    background-color: #fff;
}

.dropdown-menu {
    padding: 0px;
    border: none;
    box-shadow: 1px 0 8px rgb(0 0 0 / 30%), 0 0 2px rgb(0 0 0 / 15%);
}

.dropdown-menu li a {
    color: #1db8ea !important;
    padding-top: 15px;
    padding-bottom: 15px;
}

.dropdown-menu li span {
    color: #1db8ea !important;
    padding-top: 15px;
    padding-bottom: 15px;
}

.dropdown-menu li:nth-child(even) {
    border-top: solid 1px #e1e5ec;
}

// Product

.card-product {
    box-shadow: 1px 0 8px rgba(0, 0, 0, 0.3), 0 0 2px rgba(0, 0, 0, 0.15);
    min-height: 100% !important;
}

.card-product-standard {
    box-shadow: 1px 0px 20px 8px rgb(0 0 0 / 35%), 0 0 2px rgb(0 0 0 / 15%);
    min-height: 100% !important;
}

.card-product-no-height {
    box-shadow: 1px 0 8px rgba(0, 0, 0, 0.3), 0 0 2px rgba(0, 0, 0, 0.15);
}

.card {
    border: 0;
    border-radius: 1em;
}

.card-banner {
    border-radius: 1em;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 15px;
    padding-right: 5px;
}

// Modals

.modal-content {
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.5);
}

.modal label {
    padding: 0;
}

#signup-check {
    border-width: 2px;
}

#signup-check:focus {
    outline: none;
    box-shadow: none;
}

#signup-check:active {
    outline: none;
    box-shadow: none;
    background-color: #1db8ea;
    border: none;
}

// Breadcrumbs

.access-breadcrumb {
    margin-left: 64px;
    padding: 0;
}

.access-breadcrumb a {
    color: #2f669b;
    text-decoration: none;
}

.access-breadcrumb a:hover {
    color: #000046;
}

//---------- Settings Page -----------------

.shared-contact-list .border-bottom {
    border-bottom: 1px solid #1db8ea !important;
}

// Icons

.download-icon {
    color: #1db8ea;
    font-size: 2em;
}

.download-icon:hover {
    color: #049e8c;
}

.download-report:hover {
    color: #049e8c !important;
}

.remove-report:hover {
    color: white !important;
}

// Pagination

.pagination {
    display: inline-block;
    padding-left: 0;
    margin: 22px 0;
    border-radius: 4px;
}
.pagination > li {
    display: inline;
}
.pagination > li > a,
.pagination > li > span {
    position: relative;
    float: left;
    padding: 6px 12px;
    line-height: 1.42857143;
    text-decoration: none;
    color: #1db8ea;
    background-color: #fff;
    border: 1px solid #ddd;
    margin-left: -1px;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span {
    margin-left: 0;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
}
.pagination > li:last-child > a,
.pagination > li:last-child > span {
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
}
.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus {
    z-index: 2;
    color: #1db8ea;
    background-color: #eeeeee;
    border-color: #ddd;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
    z-index: 3;
    color: #fff;
    background-color: #1db8ea;
    border-color: #1db8ea;
    cursor: default;
}
.pagination > .disabled > span,
.pagination > .disabled > span:hover,
.pagination > .disabled > span:focus,
.pagination > .disabled > a,
.pagination > .disabled > a:hover,
.pagination > .disabled > a:focus {
    color: #777777;
    background-color: #fff;
    border-color: #ddd;
    cursor: not-allowed;
}
.pagination-lg > li > a,
.pagination-lg > li > span {
    padding: 10px 16px;
    font-size: 20px;
    line-height: 1.3333333;
}
.pagination-lg > li:first-child > a,
.pagination-lg > li:first-child > span {
    border-bottom-left-radius: 6px;
    border-top-left-radius: 6px;
}
.pagination-lg > li:last-child > a,
.pagination-lg > li:last-child > span {
    border-bottom-right-radius: 6px;
    border-top-right-radius: 6px;
}
.pagination-sm > li > a,
.pagination-sm > li > span {
    padding: 5px 10px;
    font-size: 14px;
    line-height: 1.5;
}
.pagination-sm > li:first-child > a,
.pagination-sm > li:first-child > span {
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
}
.pagination-sm > li:last-child > a,
.pagination-sm > li:last-child > span {
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
}

// ------- Notifications ---------

.notification {
    color: #fff;
    font: 700 12pt Montserrat;
    margin-left: 20px;
}
// .notification:hover {
//     color: #000046
// }

.notification-close {
    margin-right: 15px;
    cursor: pointer;
}
// .notification-close:hover {
//     color: #bb0046
// }

// ------- Excel Validation ---------

.excel-table-striped > tbody > tr:nth-of-type(even) {
    background-color: #d2f1fb;
    border-color: #d2f1fb;
}

.excel-table-striped > tbody > tr:nth-of-type(odd) {
    background-color: #ecf9fd;
    border-color: #ecf9fd;
}

.table > :not(:last-child) > :last-child > * {
    border-bottom: none
}

.excel-validation-message-notification {
    font-size: 11px;
    color: #02245e;
}

.excel-validation-message-error {
    font-size: 11px;
    color: #bb0046;
}

.excel-validation-message-warning {
    font-size: 11px;
    color: #a6800e;
}

.invalid-row {
    background-color: #f0c7d6 !important;
    border-color: #bb0046 !important;
}

.notification-cell {
    background-color: #a5e3f7 !important;
    border-color: #02245e !important; 
}

.invalid-cell {
    background-color: #f0c7d6 !important;
    border-color: #bb0046 !important;
}

.warning-cell {
    background-color: #fff3cd !important;
    border-color: #a6800e !important;
}

.loading-dots:after {
    font-weight: 300;
    content: '...';
    display: inline-block;
    width: 20px;
    text-align: left;
    animation: loading-dots 1.5s linear infinite;
}

@keyframes loading-dots {
  0%   { content: '...'; }
  25% { content: ''; }
  50% { content: '.'; }
  75% { content: '..'; }
}

.dropzone-container {
    background-color: rgba(255, 255, 255, 0.4);
    background-image: linear-gradient(to bottom, 
        rgba(255, 255, 255, 0.5), 
        rgba(255, 255, 255, 0.7), 
        rgba(255, 255, 255, 0.9) 
    ), url(./images/BA_example.PNG);
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 320px;
    border: 2px dashed #1db8ea;
}

// ------- Constant Contact ---------

#email_address_label_0 {
    color: #1db8ea;
    font-family: Roboto !important;
    font-size: 16px !important;
    font-weight: 400 !important;
}

#first_name_label_0 {
    color: #1db8ea;
    font-family: Roboto !important;
    font-size: 16px !important;
    font-weight: 400 !important;
}

#last_name_label_0 {
    color: #1db8ea;
    font-family: Roboto !important;
    font-size: 16px !important;
    font-weight: 400 !important;
}

#company_label_0 {
    color: #1db8ea;
    font-family: Roboto !important;
    font-size: 16px !important;
    font-weight: 400 !important;
}

#custom_field_string_your_message_label_0 {
    color: #1db8ea;
    font-family: Roboto !important;
    font-size: 16px !important;
    font-weight: 400 !important;
}

.ctct-form-button {
    background-color: #000046 !important;
    color: white !important;
    border-radius: 2em !important;
    border-color: #000046 !important;
    font-size: 12pt !important;
    padding: 0.575rem 1.25rem !important;
}

.ctct-form-button:hover {
    background-color: #1db8ea !important;
    border: 1px solid #1db8ea !important;
    color: #fff !important;
}
